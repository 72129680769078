import React from "react";
import { Route, Routes } from "react-router";

import { Home, NotFound } from "./pages";
import { Header, Footer } from "./components";
export default () => (
  <div className="app">
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    <Footer />
  </div>
);
